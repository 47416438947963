var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "orderlist bj order-container" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("el-button", {
          staticClass: "bjsearch",
          attrs: { type: "primary", icon: "el-icon-refresh" },
          on: { click: _vm.Refresh },
        }),
        _c(
          "div",
          { staticClass: "seach" },
          [
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入客户名称", clearable: "" },
                  model: {
                    value: _vm.from1.fullname,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "fullname", $$v)
                    },
                    expression: "from1.fullname",
                  },
                }),
              ],
              1
            ),
            _vm.role == "tdc"
              ? _c(
                  "div",
                  { staticClass: "inputs" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", placeholder: "请选择自提点" },
                        model: {
                          value: _vm.from1.delivery_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.from1, "delivery_id", $$v)
                          },
                          expression: "from1.delivery_id",
                        },
                      },
                      _vm._l(_vm.arrList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入订单编号", clearable: "" },
                  model: {
                    value: _vm.from1.order_no,
                    callback: function ($$v) {
                      _vm.$set(_vm.from1, "order_no", $$v)
                    },
                    expression: "from1.order_no",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mr10" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "time-arrow-control": true,
                    type: "datetimerange",
                    format: "yyyy-MM-dd HH:mm",
                    "value-format": "yyyy-MM-dd HH:mm",
                    "range-separator": "—",
                    "start-placeholder": "支付时间起",
                    "end-placeholder": "支付时间止",
                  },
                  on: { change: _vm.payChange },
                  model: {
                    value: _vm.pay_at,
                    callback: function ($$v) {
                      _vm.pay_at = $$v
                    },
                    expression: "pay_at",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "inputs" },
              [
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", placeholder: "请选择状态" },
                    model: {
                      value: _vm.from1.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.from1, "state", $$v)
                      },
                      expression: "from1.state",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "全部订单状态", value: "" },
                    }),
                    _c("el-option", { attrs: { label: "待付款", value: "1" } }),
                    _c("el-option", { attrs: { label: "已付款", value: "2" } }),
                    _c("el-option", { attrs: { label: "已取消", value: "3" } }),
                    _c("el-option", {
                      attrs: { label: "已生成供货单", value: "4" },
                    }),
                    _c("el-option", { attrs: { label: "已完成", value: "5" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.search },
              },
              [_vm._v("查询")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "warning", icon: "el-icon-upload2" },
                on: { click: _vm.exportHandle },
              },
              [_vm._v("导出")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "auto-table-flex" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              height: "100%",
              border: true,
              "header-cell-style": { color: "#333333", background: "#EFF6FF" },
              "tooltip-effect": "dark",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                width: "100px",
                align: "center",
                label: "序号",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "order_no", align: "center", label: "订单编号" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.empty(scope.row.order_no)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "create_time",
                align: "center",
                label: "订单创建时间",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$empty.empty(scope.row.create_time)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "pay_at", align: "center", label: "客户名称" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.member
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(scope.row.member.fullname)
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "pay_at", align: "center", label: "支付时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.$empty.time(scope.row.pay_at)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "订单状态" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.state == 1
                        ? _c("span", [_vm._v("待付款")])
                        : scope.row.state == 2
                        ? _c("span", [_vm._v("已付款")])
                        : scope.row.state == 3
                        ? _c("span", [_vm._v("已取消")])
                        : scope.row.state == 4
                        ? _c("span", [_vm._v("已生成供货单")])
                        : scope.row.state == 5
                        ? _c("span", [_vm._v("已完成")])
                        : _c("span", [_vm._v("—")]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "订单金额" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$empty.empty(
                              _vm.tool.format_money(scope.row.order_amt)
                            )
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "城市仓" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.store_delivery &&
                        scope.row.store_delivery.logistics
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.store_delivery.logistics.name
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { prop: "name", align: "center", label: "自提点" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.store_delivery
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$empty.empty(
                                    scope.row.store_delivery.name
                                  )
                                ) +
                                " "
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          staticClass: "main-color pointer text-none",
                          attrs: {
                            to: {
                              name: "ReceivingOrderDetail",
                              query: { id: scope.row.id },
                            },
                          },
                        },
                        [_vm._v("查看详情 ")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "fy" },
      [
        _c("el-pagination", {
          attrs: {
            type: "primary",
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.from1.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }